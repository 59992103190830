import trackerApi from "../api/tracker";
import { navigate } from "gatsby";

export const addSponsor = async ({ logo, name }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const sponsor = await trackerApi.post(
      "/addSponsor/",
      {
        token,
        logo,
        name,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    navigate("/app/allSponsors");
    return sponsor.data;
  } catch (error) {
    console.log("error can't create a sponsor : ", error);
  }
};

export const getAllSponsors = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getSponsor", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });
    return response.data;
  } catch (error) {
    console.log("error to get all sponsors :", error);
  }
};

export const getSponsorById = async (id) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const sponsor = await trackerApi.get(`/getSponsorById/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { token, id },
    });
    return sponsor.data;
  } catch (error) {
    console.log("error can't get a sponsor : ", error);
  }
};

export const updateSponsorById = async ({ sponsorId, logo, name }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    await trackerApi.post(
      "/updateSponsor",
      {
        sponsorId,
        logo,
        name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/app/allSponsors");
  } catch (error) {
    console.log("ERROR can't update a sponsor");
  }
};

export const deleteSponsor = async ({ sponsorId }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    await trackerApi.post(
      "/deleteSponsor/",
      {
        sponsorId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/app/allSponsors");
  } catch (error) {
    console.log("ERROR can't delete a sponsor");
  }
};

export const addSponsorImg = async ({ imgEventsBase64, sponsorId }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const addImageSelected = await trackerApi.post(
      "/imgSponsor",
      {
        token,
        imgEventsBase64,
        sponsorId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("addImageSelected :", addImageSelected);
  } catch (error) {
    console.log("ERROR can't add image for sponsor");
  }
};
