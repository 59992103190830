import React, { useState, useContext } from "react";
import "../../assets/style/globalStyle.scss";
import { addSponsor } from "../../services/sponsorService";
import { navigate } from "gatsby";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

const CreateSponsorPage = () => {
  const dispatch = useContext(GlobalDispatchContext);

  const [name, setName] = useState("");

  return (
    <div className="box">
      <h3 className="title mt-3">AJOUTER UN SPONSOR</h3>
      <h4 className="subtitle">
        Formulaire d'ajout d'un sponsor pour un admin BNC
      </h4>
      <p>* Champ obligatoire</p>
      
      <br/>

      <div className="field">
        <label className="label">
          Nom du sponsor *
          <input
            className="input"
            type="text"
            placeholder="Nom du sponor"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </label>
      </div>
     
      <br/>

      <div className="columns is-multiline">
        <div className="column">
          <button
            className="button is-success is-outlined"
            onClick={async () => {
              const newSponsor = await addSponsor({
                logo: "",
                name: name,
              });
              dispatch({
                type: "get_sponsor",
                payload: newSponsor,
              });
              navigate("/app/updateSponsorPage");
            }}
          >
            Ajout Sponsor
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateSponsorPage;
